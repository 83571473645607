@import "../icons.scss";

.icon-arrow-simple-select-autocomplete {
    background: no-repeat $icon-arrow;
    background-position-y: center;
    background-size: contain;
    margin : 14px 0px 0px -30px;;
    width: 20px;
    height: 20px;
    float: right;
    position: absolute;
    cursor: pointer;
  }
.options-style-simple-select:hover {
    background-color: #ecf5ff;
}
  