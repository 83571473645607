._order-sends_container {
    display: block;
    margin-top: 3vh;
    @extend .font-OpenSans-Regular 
}

._order-sends_container h2 {
    color: #000a12;
}

._order-sends_grouping-table {
    position: relative;
}

._order-sends_box-tab {
    position: absolute;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    top: -8px;
    width: 16%;
    height: 71px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 1px 3px 0 rgba(236, 236, 236, 0.5);
    background-color: #f6f8fa;
}

._order-sends_box-tab::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 3px;
    bottom: 0px;
    background-color: #fdc130;
}

._order-sends-tittle {
    font-size: 20px;
    font-weight: bold;
    color: $bank-blue;
}

._order-sends-count {
    margin-left: 10%;
    border: solid 1px $bank-blue;
    font-size: 14px;
    border-radius: 50%;
    height: 23px;
    padding: 0px 5px;
    font-weight: bold;
    color: #002c76;
}

._order-sends_space {
    margin-left: 11%;
    margin-bottom: 6vh;
}

._order-sends_update {
    display: flex;
    align-items: center;
    z-index: 15;
    position: absolute;
    top: 85px;
    right: 50px;
    font-size: 12px;
    font-weight: 600;
    color: $bank-blue;
    cursor: pointer;

}

._oder_sends-refresh-icon {
    margin-left: 6px;
    width: 11px;
    height: 11px;
    background-image: $refresh-icon;
}