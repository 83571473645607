@import "../icons.scss";
@import "../fonts.scss";
.modal-content-extra {
    flex-direction: column;
}
.header-modal {
    display: flex;
    justify-content: center;
    padding: 1rem 1rem;
}
.body-modal {
    padding: 0rem 1rem 1rem 1rem;
    text-align: center;
}
.icon-warning {
    background: no-repeat $icon-alert;
    background-position-y: center;
    width: 53px;
    height: 53px;
}
.icon-error {
    background: $error-icon;
    background-position-y: center;
    width: 53px;
    height: 53px;
}

.delete-icon {
    background: no-repeat $delete-icon;
    background-position-y: center;
    width: 53px;
    height: 53px;
}

.body-modal {
    @extend .font-OpenSans-Regular;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
}
.tittle-modal {
    color: #002c76;
    font-weight: 600;
}
.sub-tittle-modal {
    font-weight: 600;

}
.footer-modal {
    display: flex;
    flex-direction: row;
    height: 50px;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
}
.footer-button-modal {
    border: solid 1px #d6d6d6;;
    width: 50%;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #002c76;   
}
.width-100 {
    width: 100%;
}
