body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f8fa !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

//setup
@import "./assets/styles/fonts.scss";
@import "./assets/styles/icons.scss";
@import "./assets/styles/colors.scss";


//import styles views
@import "./assets/styles/ViewsStyles/_orderSends.scss";

//import styles components
@import "./assets/styles/ComponentsStyles/table.scss";