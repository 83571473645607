@import "./icons.scss";
@import "./fonts.scss";
@import "./colors.scss";

.report-order-title {
    @extend .font-DTLArgoT;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.77;
    letter-spacing: normal;
    color: #002c76;
    text-align: start;
}
.fields-obligatory-subtitle {
    font-family: Helvetica;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #bebebe;
    text-align: start;
}

.back-link-arrow {
    background: no-repeat $icon-arrow;
    background-size: contain;
    width: 15px;
    height: 15px;
    cursor: pointer;
    transform: rotate(90deg);
    margin-right: 2%;
}
.span-error-length{
    display: block;
    margin-left: 38%;
    font-size: 10px;
    color: #d0021b;

}
.expdition-error {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $red;
}