@import "src/assets/styles/icons.scss";
@import "src/assets/styles/colors.scss";
@import "src/assets/styles/flexbox.scss";
@import "src/assets/styles/fonts.scss";

.App {
  text-align: center;
}

.width-full{
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 200vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.div-background{
  width: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
}

.scroll-vertical {
  overflow-y: scroll;
}

.Tittle-documents {
  @extend .font-OpenSans-Regular;
  height: 39px;
  font-size: 25px;
  font-weight: 600;
  line-height: 2.24;
  color: #383838;
}

.product-documents {
  font-weight: normal;
}

.d-flex {
  display: flex;
}

.margin-sub-doc {
  margin-top: 18px;
}

.subtitle-documents {
  margin: 0 0 0 15px;
  font-size: 14px;
  line-height: 1.43;
  color: #002c76;
}

.icon-info-title{
  background: no-repeat $information;
  background-size: contain;
  width: 17px;
  height: 17px;
  position: relative;
  display: inline-block;
}

.div-document{
  background-color: #f6f8fa;
  box-shadow: 0 2px 4px 0 rgba(170, 192, 208, 0.5);;
  border-radius: 4px;
  float: left;
  width: 710px;
  height: 100px;
  justify-content: space-between;
  align-items: center;
}

.content-document-upload{
  justify-content: start;
}

.button-doc{
  position: relative;
  margin: 0px 12px 0 0;
  justify-content: flex-end;
  .div-button-doc{
    width: 223px;
    height: 47px;
    padding: 0 40px;
    border-radius: 100px;
    border: solid 1.5px #002c76;
    background-color: #fff;
    color: #002c76;
  }
  .image-button-doc{
      background: no-repeat $upload-arrow;
      background-size: contain;
      background-position: center;
      width: 14px;
      height: 14px;
      margin-top: 14px;
  }
  .text-button-doc {
    margin: 12px 0px 0px 10px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }
  input{
      cursor: pointer;
      height: 100%;
      position:absolute;
      top: 0;
      right: 0;
      opacity: 0;
      z-index: 99;
  }
}

.button-doc:hover{
  cursor: pointer;
}

.image-doc{
  background-position: center !important;
  width: 14px;
  height: 21px;
  padding: 0px 30px;
}
.doc-icon {
  background: $document;
}
.xls-icon {
  background: $xls-icon;
}

.text-document-type{
  @extend .font-OpenSans-Regular;
  font-weight: 600;
  color: #4a4a4a;
}

.required-doc{
  font-weight: 600;
  color: #00286a;
  margin-right: 3px;
}


.div-content{
  padding-top: 1.5%;
  align-items: center;
  width: 710px;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
}

.content-tex-doc {
  display: flex;
  width: 100%;
  text-align: left;
  align-items: flex-start;
}

.div-content-button{
  padding-top: 15%;
  display: flex;
  justify-content: flex-end;
}

.btn-sent-doc-disable {
  width: 138px;
  height: 56px;
  margin: 70px 76px 0 94px;
  border-radius: 100px;
  border: solid 2px rgba(0, 0, 0, 0.05);
  background-color: rgba(0, 0, 0, 0.05);
}

.text-btn-sent-disable {
  font-size: 16px;
  color:#b9bcbd;
  font-weight: 600;
}

.btn-sent-doc{
  width: 142px;
  height: 56px;
  margin: 80px 72px 0 94px;
  padding: 0 46.5px;
  border-radius: 100px;
  background-color: #002c76;
  cursor: pointer;
}

.text-btn-sent {
  font-size: 16px;
  color:#ffff;
  font-weight: 600;
}
.image-upload{
  background: no-repeat $check-true-greater;
  background-position: center;
  width: 14px;
  height: 28px;
  padding: 0px 30px;
}

.text-name-document {
  height: 23px;
  font-size: 14px;
  line-height: 2.86;
  color: #9b9b9b;
}

.div-upload-doc{
  width: 100%;
  float: left;
  border-top: 1px solid #cfcfcf;
}

.content-upload-doc {
  padding-right: 3%;
  background-color: #f6f8fa;
  box-shadow: 0 2px 4px 0 rgba(170, 192, 208, 0.5);;
  border-radius: 4px;
  float: left;
  width: 710px;
  height: 40px;
  align-items: center;
}

.image-delete{
  background: $trash;
  background-size: contain;
  background-position: center;
  width: 9px;
  height: 10px;
  cursor: pointer;
}

.image-margin{
  margin: 1px 6px 0 64px;
}

.text-upload-doc {
    font-size: 11px;
    font-weight: 600;
    color: #002c76;
    cursor: pointer;
}
.divider {
    margin: 2px 18px 3px;
    color: #002c76;
}

.div-delet, .div-preview, .divider {
  display: flex;
  float: left;
}

.div-image-text {
  display: flex;
  float: left;
}

.image-preview{
  background: $preview;
  background-size: contain;
  background-position: center;
  width: 14px;
  height: 12px;
  margin: 1px 6px 0 0;
  cursor: pointer;
}

.text-button-cancel, .text-button-back {
  margin: 0px 78px 17px 8px;
  font-size: 14px;
  line-height: 1.71;
  color: #002c76;
  text-align: right;
  width: 100%;
  cursor: pointer;
}

.text-button-cancel {
  text-align: right;
  white-space: pre-wrap;
  margin-left: 195px;
}

.text-button-back {
  text-align: left;
}

.div-content-menu{
  padding-top: 5%;
  width: 90%;
  justify-content: space-around;
}
.cursor-pointier {
  cursor: pointer;
}

// Header Component CSS //

.content-label-header {
  @extend .flex-self-center;
  //width: 59%;
}

.separator-header {
  padding: 0% 4%;
}

.div-fixed{
  position: fixed; 
  z-index: 100;
}

.content-header {
  @extend .flex-space-between;
  padding: 0% 2%;
  height: 70px
}

.logout-header {
  @extend .flex-justify-end;
  width: 37%;
  // background-color: red;
}

.user-icon {
  background: no-repeat $logo-user;
  background-position-x: center;
  background-position-y: center;
  background-size: contain;
  width: 14px;
  height: 14px;
  margin-right: 2%;
}

.logout-icon {
  background: no-repeat $logo-white;
  background-position-x: center;
  background-position-y: center;
  background-size: contain;
  width: 14px;
  height: 14px;
  margin-right: 2%;
}

.background-header {
  background-image: linear-gradient(to right, $dark-blue, $light-blue);
  left: 0;
  right: 0;
  top: 0;
  max-height: 70px;
}

.icon-bdb-header  {
  background: no-repeat $logo-bancodebogota-complete;
  background-position-y: center;
  background-size: contain;
  width: 130px;
  height: 70px;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.font-text-header {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.86;
  letter-spacing: normal;
  color: #fff;
}

  
