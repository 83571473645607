@import '../icons.scss';

.loading-icon{
    background: no-repeat $icon-loading;
    background-size: contain;
    height: 100px;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-position-x: center;
    background-position-y: center;
    margin: 5% 0% 5% 0%
}
.text-modal{
    text-align: center;
    color: #545454;
    font-size: 1.125em;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 5%;

}
